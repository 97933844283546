import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import pricingStyles from "../css/pricing.module.css"

const Pricing = () => (
  <Layout>
    <SEO title="Pricing" />
    <div className={pricingStyles.container}>
      <div className={pricingStyles.pricingOption}>
        <hr className={pricingStyles.divider} />
        <h4>The Bride Package $225</h4>
        <ul>
          <li>
            consultation and trial of bridal makeup application prior to wedding
            day
          </li>
          <li>bridal makeup application on wedding day</li>
          <li>skin prep custom to skin type</li>
          <li>false lashes</li>
          <li>custom touch-up kit</li>
          <li>airbrush application available for additional fee</li>
        </ul>
      </div>
      <hr className={pricingStyles.divider} />
      <div className={pricingStyles.pricingOption}>
        <h4>Makeup Application $90</h4>
        <ul>
          <li>false lashes</li>
          <li>airbrush application available for additional fee</li>
        </ul>
      </div>
      <hr className={pricingStyles.divider} />
      <div className={pricingStyles.pricingOption}>
        <h4>Hair Service $90</h4>
      </div>

      <p
        style={{ textAlign: "center", fontWeight: "bold", marginTop: "100px" }}
      >
        ***A travel fee is required for any bookings outside of Albuquerque, NM
        city limits
      </p>
    </div>
  </Layout>
)

export default Pricing
